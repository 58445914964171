import React from "react";
import { Typography } from "../../Typography";
import { GImage } from "../../GImage";
import { useDojoGlow } from "./context";
import { Flex } from "@src/components/Boxes";
import { Headline } from "./styles";

export const GiveYourSchool = () => {
  const { giveYourSchool: values } = useDojoGlow();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={12}
      css={(t) => ({ textAlign: "center", color: t.__new.colors.cloud80 })}
    >
      <GImage img={values.image} alt="xxx" css={{ maxHeight: 506 }} />
      <Headline variant={["Display4ExtraBold", "Display0ExtraBold", "Display0ExtraBold"]}>{values.title}</Headline>
      <Typography variant={["Body1", "Headline1Medium", "Headline1Medium"]} css={{ maxWidth: 700, margin: "auto" }}>
        {values.text}
      </Typography>
    </Flex>
  );
};
