import styled from "@emotion/styled";

const StickyWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
`;

export default StickyWrapper;
